import React from 'react';
import { ControlKeyType, MoveKeyType } from '../../container/noshi';
import { t } from '../../localize';

type Props = {
  onClickTitle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSize: (key: ControlKeyType) => void;
  onClickMove: (key: MoveKeyType) => void;
};

export const Controller: React.FC<Props> = ({
  onClickTitle,
  onClickSize,
  onClickMove,
}) => {
  return (
    <ul>
      <li className='c_size_all grn'>
        <span>{'表書き'}</span>
        <button className='c_left' name={'size-small'} onClick={onClickTitle}>
          {t('noshiControllerSmall')}
        </button>
        <button className='c_center' name={'size-big'} onClick={onClickTitle}>
          {t('noshiControllerBig')}
        </button>
        <button className='c_right' name={'size-reset'} onClick={onClickTitle}>
          {t('noshiControllerClose')}
        </button>
      </li>

      <li className='c_move grn'>
        <button className='c_top' name={'title-up'} onClick={onClickTitle}>
          {t('noshiControllerUp')}
        </button>
        <button className='c_bottom' name={'title-down'} onClick={onClickTitle}>
          {t('noshiControllerDown')}
        </button>
        <button className='c_left' name={'title-left'} onClick={onClickTitle}>
          {t('noshiControllerLeft')}
        </button>
        <button className='c_right' name={'title-right'} onClick={onClickTitle}>
          {t('noshiControllerRight')}
        </button>
        <button className='c_reset' name={'title-reset'} onClick={onClickTitle}>
          {t('noshiControllerClose')}
        </button>
      </li>
      <li className='c_size_all'>
        <span>{t('noshiControllerNaireAll')}</span>
        <button className='c_left' onClick={(_) => onClickSize('all-small')}>
          {t('noshiControllerSmall')}
        </button>
        <button className='c_center' onClick={(_) => onClickSize('all-big')}>
          {t('noshiControllerBig')}
        </button>
        <button className='c_right' onClick={(_) => onClickSize('all-reset')}>
          {t('noshiControllerClose')}
        </button>
      </li>

      <li className='c_size_para'>
        <div className='box1'>
          <span>{t('noshiControllerNaire1')}</span>
          <button className='c_left' onClick={(_) => onClickSize('1-small')}>
            {t('noshiControllerSmall')}
          </button>{' '}
          <button className='c_right' onClick={(_) => onClickSize('1-big')}>
            {t('noshiControllerBig')}
          </button>
        </div>
        <div className='box2'>
          <span>{t('noshiControllerNaire2')}</span>
          <button className='c_left' onClick={(_) => onClickSize('2-small')}>
            {t('noshiControllerSmall')}
          </button>{' '}
          <button className='c_right' onClick={(_) => onClickSize('2-big')}>
            {t('noshiControllerBig')}
          </button>
        </div>
        <div className='box3'>
          <span>{t('noshiControllerNaire3')}</span>
          <button className='c_left' onClick={(_) => onClickSize('3-small')}>
            {t('noshiControllerSmall')}
          </button>{' '}
          <button className='c_right' onClick={(_) => onClickSize('3-big')}>
            {t('noshiControllerBig')}
          </button>
        </div>
      </li>

      <li className='c_move'>
        <button className='c_top' onClick={(_) => onClickMove('up')}>
          {t('noshiControllerUp')}
        </button>
        <button className='c_bottom' onClick={(_) => onClickMove('down')}>
          {t('noshiControllerDown')}
        </button>
        <button className='c_left' onClick={(_) => onClickMove('left')}>
          {t('noshiControllerLeft')}
        </button>
        <button className='c_right' onClick={(_) => onClickMove('right')}>
          {t('noshiControllerRight')}
        </button>
        <button className='c_reset' onClick={(_) => onClickMove('reset')}>
          {t('noshiControllerClose')}
        </button>
      </li>
    </ul>
  );
};
