import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from './modal';
import {
  ControlKeyType,
  FontSizePosition,
  MoveKeyType,
  NoshiFormValues,
} from '../../container/noshi';
import { Controller } from './controller';
import { PrintSize } from '../../consts';
import { t } from '../../localize';

type Props = {
  formValues: NoshiFormValues;
  showUsuzumi: boolean;
  printSize: PrintSize;
  noshiClassName: string;
  isOpenModal: boolean;
  fontSizePosition: FontSizePosition;
  justReset: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  onClickTitle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSize: (key: ControlKeyType) => void;
  onClickMove: (key: MoveKeyType) => void;
  onClickUpdate: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickReset: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeForm: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
};

export const NoshiComponent: React.FC<Props> = ({
  formValues,
  showUsuzumi,
  printSize,
  noshiClassName,
  isOpenModal,
  fontSizePosition,
  justReset,
  onOpenModal,
  onCloseModal,
  onClickTitle,
  onClickSize,
  onClickMove,
  onClickUpdate,
  onClickReset,
  onChangeForm,
}) => {
  const noshiRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = React.useState(0);
  const isUsuzumi =
    formValues.kind === '黒白・七本結切（志）' && formValues.usuzumi;

  const handleResize = useCallback(() => {
    if (!noshiRef.current) return;
    const boxWidth = parseFloat(getComputedStyle(noshiRef.current).width);
    const currentWidth = window.innerWidth - 60;
    setScale(Math.min(1, currentWidth / boxWidth));
  }, [noshiRef]);

  useEffect(() => {
    handleResize();
  }, [scale, noshiClassName, handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <main className='SR_main'>
      <div className='SR_container'>
        <div className='SR_title'>
          <h2>{t('noshiTitle')}</h2>
          <ul className='btsA'>
            <li className='size'>
              {t('noshiPrintSize')}
              {printSize}
            </li>
          </ul>
          <ul className='btsA'>
            <li>
              <button className='btA' type='button' onClick={onOpenModal}>
                {t('noshiEdit')}
              </button>
            </li>
            <li>
              <button className='btA' type='button' onClick={window.print}>
                {t('noshiPrint')}
              </button>
            </li>
            <li>
              <Link className='btB' to={'/private/'}>
                {t('noshiDone')}
              </Link>
            </li>
          </ul>
        </div>

        <section className='SR_noshi_print'>
          <div className='noshi_inner rim'>
            <div
              className={`noshi ${noshiClassName}`}
              ref={noshiRef}
              style={{ transform: `scale(${scale})` }}
            >
              <div className='moji'>
                <div className='title'>
                  <h3
                    className={
                      isUsuzumi
                        ? 'usuzumi'
                        : formValues?.titleCheck
                        ? 'omotegaki'
                        : ''
                    }
                    style={{
                      fontSize:
                        !justReset && fontSizePosition.titleSize
                          ? `${fontSizePosition.titleSize}pt`
                          : undefined,
                      transform: `translate(${fontSizePosition.titleLeft}%, ${fontSizePosition.titleTop}%)`,
                    }}
                  >
                    {formValues?.titleCheck ? formValues?.title : ''}
                  </h3>
                </div>
                <article
                  className='names'
                  style={{
                    left: `${fontSizePosition.x}%`,
                    top: `${fontSizePosition.y}%`,
                  }}
                >
                  <p
                    className={`sei${isUsuzumi ? ' usuzumi' : ''}`}
                    id='o_myoji'
                    style={{
                      fontSize: `${fontSizePosition.all}%`,
                    }}
                  >
                    {formValues.familyName}
                  </p>
                  <div>
                    <pre>
                      <p
                        className={`naire1${isUsuzumi ? ' usuzumi' : ''}`}
                        id='o_naire1'
                        style={{ fontSize: `${fontSizePosition.naire1}%` }}
                      >
                        {formValues.naire1}
                      </p>
                    </pre>
                    <pre>
                      <p
                        className={`naire2${isUsuzumi ? ' usuzumi' : ''}`}
                        id='o_naire2'
                        style={{ fontSize: `${fontSizePosition.naire2}%` }}
                      >
                        {formValues.naire2}
                      </p>
                    </pre>
                    <pre>
                      <p
                        className={`naire3${isUsuzumi ? ' usuzumi' : ''}`}
                        id='o_naire3'
                        style={{ fontSize: `${fontSizePosition.naire3}%` }}
                      >
                        {formValues.naire3}
                      </p>
                    </pre>
                  </div>
                </article>
              </div>
            </div>

            <div className='SR_noshi_control'>
              <Controller
                onClickTitle={onClickTitle}
                onClickSize={onClickSize}
                onClickMove={onClickMove}
              />
            </div>
          </div>

          <Modal
            formValues={formValues}
            showUsuzumi={showUsuzumi}
            isOpen={isOpenModal}
            close={onCloseModal}
            onClickUpdate={onClickUpdate}
            onClickReset={onClickReset}
            onChangeForm={onChangeForm}
          />
        </section>
      </div>
    </main>
  );
};
